import React from 'react'

import Seo from '../components/seo'
import Layout from '../components/layout'

import * as styles from '../templates/blog-post.module.css'
import * as products from '../components/products.module.css';

class aboutIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          title="About"
          description="More about me and my journey as a creative."
          image="/og-image.png"
          />
        <div className={products.background}>
          <header className={products.productsHeader}>
            <div className={products.container}>
              <h1>About me</h1>
              <p>My journey as a creative.</p>
            </div>
          </header>
          <div className={styles.container}>
            <div className={styles.article}>
              <div className={styles.body}>
              From a young age, I was already curious about building all sorts of things. Build with Lego, build houses in The Sims, making theme parks & rollercoasters in Rollercoaster Tycoon, and the list goes on.
              <br/> <br />
              When I grew older, I started to make more complex projects. I started making batch programs that were interactive choose your own adventure stories, and small websites with different colors and interactions, I even started making small interactive games that I showed to my classmates, and then in return, I would ask for feedback so I could improve them. I discovered I liked making digital products.
              <br/> <br />
              That passion for building things I took with me to the <a href="https://www.glr.nl/" target="_blank" rel="noreferrer">Grafisch Lyceum in Rotterdam</a> where I studied media technology. I learned how to build web applications with HTML & CSS, make them interactive with JavaScript, and connect the frontend with the backend using PHP & MySQL. I learned to make mobile applications using Android Studio and Xcode and finally, I learned to make games using the Unity engine & C#.
              <br/> <br />
              I had a great time at the Grafisch Lyceum and I learned so much about different technologies powering our digital world.
              <br /> <br />
              <img src="/about/glr.jpg" alt="Grafisch Lyceum Rotterdam" title="Grafisch Lyceum Rotterdam" />
              <br/>
              In the end, I also started an awesome front-end developer internship at a creative digital agency in Rotterdam called <a href="https://dotcontrol.com/" target="_blank" rel="noreferrer">DotControl</a>. I learned so much while being an intern there. I learned to build fast, responsive, SEO-friendly websites with all kinds of web technologies such as:
              <br/> <br/>
              <ul>
                <li>JavaScript(ES6)</li>
                <li>Typescript</li>
                <li>Sass</li>
                <li>Webpack</li>
                <li>and many more.</li>
              </ul>
              <br/>
              Not only that. I also learned to work with clients, designers, other developers, and growth hackers. It was an awesome time and I was very happy to sign my first full-time work contract at the end of my internship.
              <br/> <br/>
              <img src="/about/dotcontrol.png" alt="Me working at control" title="Me working at control" />
              <br/>
              After being at DotControl for a while I decided I wanted to go back to school. I am still young and eager to learn so why not? I searched for different programs and schools, looking for the one that fits my creative needs the best.
              <br /> <br/>
              In the end, I stumbled upon the Hogeschool van Amsterdam (also known as Amsterdam University of Applied Sciences). My interest was in the four-year bachelor of science program called <a href="https://www.cmd-amsterdam.nl/" target="_blank" rel="noreferrer">Communication and Multimedia Design</a>. This program teaches a wide range of topics such as visual design, digital interaction, UI, UX, Front-end, and many more. I learned to work with tools such as Figma, Invision, Adobe programs such as Premiere, Illustrator, and so on.
              <br /> <br/>
              At the end of the first year, I got all my 60 ECTS which earned me my propedeuse. My final grade was an 8,1. You could say I am at my place.
              <br /> <br />
              In my second year, I started an internship at a digital product design agency called <a href="https://hike.one/" target="_blank" rel="noreferrer">Hike One</a>, as a visual designer. Hike One primary focus is design. Visual design, user experience design, product management, UX research, etc.
              <br />
              During my internship, I learned a lot about visual &amp; exploration, working together with other designers/clients, Working in design tools such as Figma and Protopie, and many more qualities I wanted to learn as a beginning designer.. I had a great time at Hike One and at the end of my internship, I got a contract so that I can work part-time at Hike while I'm still studying.
              <br /> <br />
              <img src="/about/hikeone.jpeg" alt="Hike One digitial product design agency" title="Hike One digitial product design agency" />
              <br />
              In my third year, I did an exchange semester at the Toronto Metropolitan University in Canada. I attended the Creative School, an ambitious faculty within the University focusing on all kinds of creative studies. The exchange semester really helped me improve my collaboration and working skills in an international environment while also making some new international friends.
              <br /> <br />
              <img src="/about/toronto.jpg" alt="Toronto skyline view" title="Toronto skyline view" />
              <br />
              After the Toronto exchange, I applied and worked at DEPT® agency. A big agency with thousands of employees around the globe. I worked on ambitious, big projects and learned a lot in such a short while. My knowledge about design systems, variables, big projects, pixel perfect design grew more and more.
              <br /><br />
              <img src="/about/dept.jpg" alt="DEPT office in Rotterdam" title="DEPT office in Rotterdam" />
              <br />
              And now after four years I'm finally done with my CMD study at the Amsterdam University of Applied Sciences. Who knows what the feature will bring...
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default aboutIndex
